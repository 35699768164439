import { BiDownload } from 'react-icons/bi'
import { AnimatedButton } from '../action-button-drawer'
import { TbMath } from 'react-icons/tb'

export default function ChangeNumericModeButton(props) {
  const toggleNumericalMode = () => {
    props.setNumericalMode((v) => !v)
  }

  return (
    <AnimatedButton
      color={props.color}
      isActive={props.numericalMode}
      buttonColor={props.buttonColor}
      function={toggleNumericalMode}
      icon={TbMath}
      text={'Numeric Mode'}
    />
  )
}
