import { useColorMode, useColorModeValue } from '@chakra-ui/react'
import { AnimatedButton } from '../action-button-drawer'
import { MoonIcon } from '../icons/moon-icon'
import { SunIcon } from '../icons/sun-icon'

export default function DarkModeButton(props) {
  const { toggleColorMode } = useColorMode()
  const SwitchIcon = useColorModeValue(SunIcon, MoonIcon)
  const iconText = useColorModeValue(
    'Switch to Dark Mode',
    'Switch to Light Mode'
  )
  return (
    <AnimatedButton
      buttonColor={props.buttonColor}
      function={() => toggleColorMode()}
      icon={SwitchIcon}
      text={iconText}
    />
  )
}
