import { GiHamburgerMenu } from 'react-icons/gi'
import { RxRotateCounterClockwise } from 'react-icons/rx'
import { AnimatedButton } from '../action-button-drawer'

export default function RotatorButton(props) {
  const rotationFunction = () => {
    props.setZRotationMultiplier((currentValue) => (currentValue + 1) % 4)
  }

  return (
    <AnimatedButton
      isActive={props.zRotationMultiplier}
      color={props.color}
      buttonColor={props.buttonColor}
      function={rotationFunction}
      icon={RxRotateCounterClockwise}
      text={'Rotate'}
    />
  )
}
