import {
  Flex,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  useColorModeValue,
} from '@chakra-ui/react'
import { Tooltip } from '@chakra-ui/react'
import { GeometryState, GeometryStateType } from 'lib/state'

interface CustomSliderProps {
  sliderProps: {
    orientation: 'vertical' | 'horizontal' | undefined
    value: number
    width?: string
    min: number
    step: number
    onChange: any
    max: number
    isDisabled?: boolean
  }
  geometryState: GeometryState
  containerProps?: any
  showEnds?: boolean
  setModelBeingUpdated: any
}

export default function CustomSlider(props: CustomSliderProps) {
  const textPrimaryColor = useColorModeValue(
    'textPrimary.dark',
    'textPrimary.light'
  )
  const backgroundColorMain = useColorModeValue(
    'background.light',
    'background.dark'
  )

  return (
    <Flex
      flex={1}
      basis={0}
      direction={props.sliderProps.orientation == 'vertical' ? 'column' : 'row'}
      alignItems={'center'}
      position={'relative'}
      {...props.containerProps}
    >
      {props.showEnds && (
        <Flex
          borderRadius={'50%'}
          position={'relative'}
          top={'0.5em'}
          borderWidth={'2px'}
          zIndex={2}
          borderColor={textPrimaryColor}
          backgroundColor={backgroundColorMain}
          width={'1em'}
          height={'1em'}
        />
      )}
      <Flex flex={1} basis={0}>
        <Slider
          {...props.sliderProps}
          onChangeStart={() => {
            props.geometryState.putCurrentStateInPreviousStates()
            props.setModelBeingUpdated(true)
          }}
          onChangeEnd={() => props.setModelBeingUpdated(false)}
        >
          <SliderTrack bgColor={textPrimaryColor} width={'2px'} height={'2px'}>
            <SliderFilledTrack
              bgColor={textPrimaryColor}
              width={'2px'}
              height={'2px'}
            />
          </SliderTrack>
          <Tooltip label={props.sliderProps.value}>
            <SliderThumb bgColor={textPrimaryColor} zIndex={3} />
          </Tooltip>
        </Slider>
      </Flex>
      {props.showEnds && (
        <Flex
          borderRadius={'50%'}
          position={'relative'}
          bottom={'0.5em'}
          borderWidth={'2px'}
          zIndex={2}
          borderColor={textPrimaryColor}
          backgroundColor={backgroundColorMain}
          width={'1em'}
          height={'1em'}
        />
      )}
    </Flex>
  )
}
