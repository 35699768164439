import { Box, Flex, Spinner } from '@chakra-ui/react'
import ThreeDots from './three-dots'

export default function LoadingNotifier() {
  const left = {
    base: window.innerWidth / 3,
    md: '2em',
    xl: window.innerWidth / 5,
  }
  const top = window.innerHeight / 2

  return (
    <Flex
      position={'absolute'}
      direction={{ base: 'column', md: 'row' }}
      zIndex={16}
      left={left}
      top={top}
      alignItems='center'
    >
      <ThreeDots
        marginLeft={{ base: 'unset', md: '1em' }}
        marginTop={{ base: '1em', md: 'unset' }}
      />
    </Flex>
  )
}
