import { BiReset } from 'react-icons/bi'
import { AnimatedButton } from '../action-button-drawer'

export default function ResetButton(props) {
  const resetState = () => {
    props.geometryState.resetState(props.geometryState.modelType)
  }

  return (
    <AnimatedButton
      buttonColor={props.buttonColor}
      function={resetState}
      icon={BiReset}
      text={'Reset All'}
    />
  )
}
