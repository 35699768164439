import { TfiHelp } from 'react-icons/tfi'
import { AnimatedButton } from '../action-button-drawer'

export default function HelpButton(props) {
  const showTutorialPopup = () => {
    props.setShowTutorialPopup(true)
  }
  return (
    <AnimatedButton
      buttonColor={props.buttonColor}
      function={showTutorialPopup}
      icon={TfiHelp}
      text={'Help'}
    />
  )
}
