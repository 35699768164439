import { BsArrowDownUp } from 'react-icons/bs'
import { AnimatedButton } from '../action-button-drawer'
import { GeometryState, WebsiteState } from 'lib/state'

export default function ObjectSwitcherButton({
  websiteState,
  geometryState,
  color,
  buttonColor,
}: {
  websiteState: WebsiteState
  geometryState: GeometryState
  color: string
  buttonColor: string
}) {
  const switcherFunction = () => {
    geometryState.importState(
      websiteState.exportedObjects[
        websiteState.selectedObjectIndex === 0 ? 1 : 0
      ]
    )
    geometryState.setPreviousStates([])
    websiteState.setSelectedObjectIndex((curIndex) => (curIndex === 0 ? 1 : 0))
  }

  return (
    <AnimatedButton
      isActive={false}
      color={color}
      buttonColor={buttonColor}
      function={switcherFunction}
      icon={BsArrowDownUp}
      text={'Rotate'}
    />
  )
}
