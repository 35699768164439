import { GeometryState } from 'lib/state'
import { BiUndo } from 'react-icons/bi'
import { AnimatedButton } from '../action-button-drawer'

export default function UndoButton({
  buttonColor,
  geometryState,
}: {
  buttonColor: string
  geometryState: GeometryState
}) {
  const undoFunction = () => {
    geometryState.undoState()
  }

  return (
    <AnimatedButton
      buttonColor={buttonColor}
      function={undoFunction}
      icon={BiUndo}
      text={'Undo'}
    />
  )
}
