import { Flex, Input, Link, useStyleConfig } from '@chakra-ui/react'
import { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { motion } from 'framer-motion'
import { GeometryState, WebsiteState, getAllObjectsSerialized } from 'lib/state'
import { NextRouter } from 'next/router'
import { schemaVersion } from 'lib/constants'

export default function DDWOption({
  geometryState,
  router,
  borderColor,
  websiteState,
  etsy,
}: {
  geometryState: GeometryState
  router: NextRouter
  borderColor: string
  websiteState: WebsiteState
  etsy?: boolean
}) {
  const pickerStyles = useStyleConfig('FieldBox', { variant: 'pickerInput' })
  const [value, setValue] = useState('')

  const changeHandler = (value: any) => {
    setValue(value.target.value)
  }

  const uploadDesign = async () => {
    try {
      const name = value
      let uuid = uuidv4()

      await router.replace(
        {
          pathname: '/',
          query: {},
        },
        undefined,
        { shallow: true }
      )

      var res1: any = await fetch(window.location.origin + '/api/posts', {
        method: 'POST',
        body: JSON.stringify({
          _id: uuid,
          value: getAllObjectsSerialized(websiteState.exportedObjects),
          version: schemaVersion,
        }),
      })

      var res2: any = await fetch(window.location.origin + '/api/urls', {
        method: 'POST',
        body: JSON.stringify({
          _id: uuid,
          value: getAllObjectsSerialized(websiteState.exportedObjects),
          version: schemaVersion,
        }),
      })
    } catch (e) {
      console.log('error', e)
      res1 = { status: 500 }
      res2 = { status: 500 }
    }

    if (res1.status == 200 && res2.status == 200) {
      alert('Your design has been saved to the database, you can now view it')
    } else {
      alert('Your design was not saved. Please check your internet connection')
    }
  }

  return (
    <Flex marginY={'0.5em'} direction={'column'} width={'80%'}>
      <Link href={'/models'}>
        <Flex
          justifyContent={'center'}
          backgroundColor={geometryState.color}
          paddingY={'0.5em'}
          borderRadius={'0.5em'}
          borderWidth={'2px'}
          borderColor={borderColor}
          cursor={'pointer'}
        >
          Design Inspirations
        </Flex>
      </Link>

      {!etsy && (
        <Flex marginTop={'0.5em'}>
          <Flex
            marginRight={'1em'}
            width={'100%'}
            position={'relative'}
            __css={pickerStyles}
          >
            <Flex alignItems={'center'}>
              <Input
                type={'text'}
                fontSize={'1em'}
                height={'1.3em'}
                border={0}
                marginLeft={'0.5em'}
                placeholder={'Enter Name'}
                value={value}
                onChange={changeHandler}
              />
            </Flex>
          </Flex>

          <Flex
            onClick={uploadDesign}
            backgroundColor={geometryState.color}
            padding={'0.5em'}
            borderRadius={'0.5em'}
            borderWidth={'2px'}
            borderColor={borderColor}
            cursor={'pointer'}
            //@ts-ignore
            as={motion.div}
            whileHover={{
              textDecoration: 'underline',
            }}
          >
            Save
          </Flex>
        </Flex>
      )}
    </Flex>
  )
}
