import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
  Button,
  useColorModeValue,
  Box,
  useStyleConfig,
  useDimensions,
  Center,
  Flex,
} from '@chakra-ui/react'
import { constants, listOfPickerColors } from 'lib/constants'
import { GoChevronDown } from 'react-icons/go'
import { pickTextColorBasedOnBgColor } from 'lib/utils'
import { Dispatch, MutableRefObject, SetStateAction, useRef } from 'react'
import { useSize } from '@chakra-ui/react-use-size'
import { motion } from 'framer-motion'

//TODO remove any from here
function CustomMenuItem(props: any) {
  return (
    <Center borderRadius={'inherit'}>
      <MenuItem
        backgroundColor={'transparent'}
        borderRadius={'inherit'}
        height={'2em'}
        width={'80%'}
        padding={'0'}
        marginTop='1em'
      >
        <Box
          backgroundColor={'#' + props.color}
          as={motion.div}
          transition='0.1s spring'
          whileHover={{ scale: 1.1 }}
          shadow={'dark-lg'}
          height={'100%'}
          width={'100%'}
          borderRadius={'inherit'}
          onClick={() => {
            props.setColor(props.color)
          }}
        />
        <Box />
      </MenuItem>
    </Center>
  )
}

interface ColorPickerProps {
  color: string
  setColor: Dispatch<SetStateAction<string>>
}

export default function ColorPicker({
  color,
  setColor,
}: ColorPickerProps): JSX.Element {
  const menuButtonRef: MutableRefObject<HTMLButtonElement | null> = useRef(null)
  const menuButtonDimensions = useSize(menuButtonRef)

  const styles = useStyleConfig('FieldBox', { variant: 'pickerInput' })

  return (
    <Menu>
      {({ isOpen }) => (
        <>
          <MenuButton
            as={Button}
            __css={styles}
            ref={menuButtonRef}
            margin={'1em'}
            width={'80%'}
            _hover={{ filter: 'brightness(60%)' }}
          >
            <Flex
              width={'100%'}
              height={'100%'}
              overflowY={'hidden'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Flex
                width={'100%'}
                height={'1.2em'}
                backgroundColor={'#' + color}
                marginRight={'1em'}
                borderRadius={'0.5em'}
                borderWidth={'2px'}
                borderColor={'#FFF'}
              />
              <Flex alignItems={'center'}>
                |
                <GoChevronDown />
              </Flex>
            </Flex>
          </MenuButton>
          <MenuList
            overflowY={'auto'}
            maxH={'40vh'}
            paddingBottom={'1em'}
            width={
              menuButtonDimensions ? menuButtonDimensions.width : 'inherit'
            }
            zIndex={6}
          >
            {listOfPickerColors.map((currentColor) => (
              <CustomMenuItem
                key={currentColor}
                setColor={setColor}
                color={currentColor}
                listBackgroundColor={color}
              ></CustomMenuItem>
            ))}
          </MenuList>
        </>
      )}
    </Menu>
  )
}
