import { Box, Flex } from '@chakra-ui/react'
import { Dispatch, SetStateAction } from 'react'
import { IoIosArrowUp } from 'react-icons/io'

export default function PortraitCollapseButton(props: {
  sidebarOpen: boolean
  setSidebarIsOpen: Dispatch<SetStateAction<boolean>>
}) {
  const toggleFunction = () => {
    props.setSidebarIsOpen((v) => !v)
  }
  return (
    <Flex
      transform={props.sidebarOpen ? 'rotate(180deg)' : 'rotate(0deg)'}
      width={'100%'}
      justifyContent={'center'}
      transition='all 1s ease'
      onClick={toggleFunction}
    >
      <IoIosArrowUp transform={'rotate(180deg)'} fontSize={'2em'} />
    </Flex>
  )
}
