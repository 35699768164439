import { BiDownload } from 'react-icons/bi'
import { AnimatedButton } from '../action-button-drawer'
import { TbRuler2 } from 'react-icons/tb'

export default function InfoButton(props) {
  const toggleInfoMode = () => {
    props.setInfoMode((v) => !v)
  }

  return (
    <AnimatedButton
      color={props.color}
      isActive={props.infoMode}
      buttonColor={props.buttonColor}
      function={toggleInfoMode}
      icon={TbRuler2}
      text={'Show Info (cm)'}
    />
  )
}
