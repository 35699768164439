import { useSize } from '@chakra-ui/react-use-size'
import { modelTypes } from 'lib/constants'
import { MeshMaker, ShapeCurve } from 'lib/mesh-maker'
import { useRouter } from 'next/router'
import { MutableRefObject, useCallback, useEffect, useRef } from 'react'
import { getInitialSpline } from '../components/spline-editor'
import {
  GeometryState,
  WebsiteState,
  deserializeAndSetState,
  getAllObjectsSerialized,
  setStateFromFetched,
} from 'lib/state'

export function generateIndexPageHooks() {
  const geometryState = new GeometryState(modelTypes.e27)
  const websiteState = new WebsiteState()

  const router = useRouter()

  const canvasRef: MutableRefObject<HTMLCanvasElement | null> = useRef(null)
  const canvasSize = useSize(canvasRef)

  const lampSetter = () => {
    websiteState.setHasMounted(router.isReady)

    async function fetchCode() {
      websiteState.setShowLoader(true)
      try {
        let res = await fetch(
          window.location.origin + '/api/urls?id=' + router.query.id,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        let allPosts = await res.json()

        if (allPosts.status == 200 && allPosts.data.length > 0) {
          setStateFromFetched(websiteState, geometryState, allPosts.data[0])
        } else {
          alert(
            'We were not able to fetch your design from the database. You may have supplied a wrong ID or the object has expired'
          )
        }
      } catch (e) {
        console.log(e)
        alert(
          'We were not able to fetch your design from the database. Please check your internet connection'
        )
      }
      websiteState.setShowLoader(false)
    }

    if (router.isReady) {
      if (router.query.paymentSuccess !== undefined) {
        alert(
          'Your order was placed successfully. You will receive an email shortly'
        )
      } else if (router.query.paymentFailure !== undefined) {
        alert(
          'We were not able to complete your order. If the money has been deducted it will be refunded within 3-5 business days'
        )
      }

      if (router.query.id) fetchCode()
      else if (window.localStorage.getItem('state'))
        deserializeAndSetState(
          websiteState,
          geometryState,
          window.localStorage.getItem('state')!
        )
    }
  }

  const timeOutRef: MutableRefObject<NodeJS.Timeout | undefined> = useRef()
  // const meshWorkerCaller = useCallback(() => {
  //   timeOutRef.current = setTimeout(() => {
  //     let meshMaker = new MeshMaker(
  //       geometryState,
  //       websiteState.modelBeingUpdated,
  //       websiteState.hideModifier
  //     )

  //     const doneMesh = meshMaker.getMesh(false, websiteState.modelBeingUpdated)
  //     websiteState.setMeshObject([doneMesh[0]])
  //   }, geometryState.modelType.updationTimeout)
  // }, [
  //   geometryState.spline,
  //   geometryState.profiles,
  //   geometryState.color,
  //   geometryState.modelType,
  //   websiteState.modelBeingUpdated,
  //   geometryState.surfaceModifier,
  //   geometryState.spheres,
  //   geometryState.disks,
  //   geometryState.additionalOptions,
  // ])

  const meshSetter = () => {
    if (
      geometryState.spline != null &&
      geometryState.profiles != null &&
      geometryState.spline.knots.length > 1
    ) {
      if (timeOutRef.current) clearTimeout(timeOutRef.current)

      let meshMaker = new MeshMaker(
        geometryState,
        websiteState.modelBeingUpdated,
        websiteState.hideModifier
      )
      const doneMesh = meshMaker.getMesh(
        websiteState.hasMounted,
        websiteState.modelBeingUpdated
      )

      websiteState.setExportedObjects((currentExportedObjects) => {
        const newExportedObjects = [...currentExportedObjects]
        newExportedObjects[websiteState.selectedObjectIndexRef.current] =
          geometryState.exportState()

        if (!websiteState.modelBeingUpdated) {
          const serializedState = getAllObjectsSerialized(newExportedObjects)
          window.localStorage.setItem('state', serializedState)
        }
        return newExportedObjects
      })

      websiteState.setMeshObject((currentMeshObjects) => {
        const newMeshObjects = [...currentMeshObjects]
        newMeshObjects[websiteState.selectedObjectIndexRef.current] =
          doneMesh[0]
        return newMeshObjects
      })

      websiteState.setBoundingBox((currentBoundingBoxObjects) => {
        const newBoundingBoxObjects = [...currentBoundingBoxObjects]
        newBoundingBoxObjects[websiteState.selectedObjectIndexRef.current] =
          doneMesh[1]
        return newBoundingBoxObjects
      })

      // if (!websiteState.modelBeingUpdated) {
      //   const serializedState = getAllObjectsSerialized(
      //     websiteState.exportedObjects
      //   )
      //   window.localStorage.setItem('state', serializedState)
      // }
      // if (!websiteState.modelBeingUpdated) meshWorkerCaller()
    }
  }

  useEffect(lampSetter, [router.isReady])

  useEffect(() => {
    websiteState.setSidebarIsOpen(
      websiteState.isLandscape ? websiteState.isLandscape : false
    )
  }, [websiteState.isLandscape])

  useEffect(meshSetter, [
    geometryState.spline,
    geometryState.profiles,
    geometryState.color,
    geometryState.modelType,
    websiteState.modelBeingUpdated,
    geometryState.surfaceModifier,
    geometryState.spheres,
    geometryState.disks,
    geometryState.additionalOptions,
    geometryState.flipped,
    websiteState.selectedObjectIndex,
  ])

  useEffect(() => {
    if (websiteState.hasMounted) {
      if (!window.localStorage.getItem('showTutorial'))
        websiteState.setShowTutorialPopup(true)
    }
  }, [websiteState.hasMounted])

  const undoCaller = (event: KeyboardEvent) => {
    if (event.key === 'z' && event.ctrlKey) {
      geometryState.undoState()
    }
  }
  useEffect(() => {
    document.addEventListener('keydown', undoCaller, false)
  }, [])

  return {
    geometryState,
    websiteState,
    router,
    lampSetter,
    meshSetter,
    canvasRef,
    canvasSize,
  }
}
