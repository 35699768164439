import {
  Box,
  Flex,
  Tooltip,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react'
import { motion } from 'framer-motion'
import { useEffect, useState } from 'react'
import { pickTextColorBasedOnBgColor } from 'lib/utils'
import IosARExpButton from './buttons/augmented-reality-experience'
import CollapseButton from './buttons/collapse-button'
import CopyButton from './buttons/copy-url-button'
import DarkModeButton from './buttons/dark-mode-button'
import DownloadSTLButton from './buttons/download-stl-button'
import InfoButton from './buttons/info-button'
import InstagramButton from './buttons/instagram-button'
import ChangeNumericMode from './buttons/numeric-mode-button'
import ResetButton from './buttons/reset-button'
import UndoButton from './buttons/undo-button'
import HelpButton from './buttons/help-button'
import RotatorButton from './buttons/rotator-button'
import { GeometryState, WebsiteState } from 'lib/state'
import { NextRouter } from 'next/router'
import { modelTypes } from 'lib/constants'
import ObjectSwitcherButton from './buttons/object-switcher-button'

//TODO remove any type from here
export function AnimatedButton(props: any) {
  return (
    <>
      {props.icon ? (
        <Tooltip label={props.text} placement={'left'} hasArrow>
          <Flex
            hidden={props.hidden}
            marginBottom={{ base: '0', md: '0.5em' }}
            marginRight={{ md: '0', base: '0.5em' }}
          >
            <Flex
              //@ts-ignore
              as={motion.div}
              backgroundColor={
                props.isActive ? '#' + props.color : props.buttonColor
              }
              whileHover={{
                borderRadius: '10px',
                width: 'unset',
                height: 'unset',
              }}
              whileTap={{ scale: 0.9 }}
              //@ts-ignore
              style={{ originX: '50%', originY: '50%' }}
              position={'relative'}
              shadow={'lg'}
              name={'Copy URL'}
              width={'4em'}
              height={'4em'}
              borderRadius={'50%'}
              onClick={props.function}
              overflow={'hidden'}
              cursor={'pointer'}
            >
              <Flex
                alignItems={'center'}
                justifyContent={'center'}
                grow={0}
                minWidth={'4em'}
                height={'4em'}
                color={
                  props.isActive
                    ? pickTextColorBasedOnBgColor(props.color)
                    : 'inherit'
                }
              >
                {props.isLoading ? (
                  <props.loadingIcon />
                ) : (
                  <props.icon fontSize={'2em'} />
                )}
              </Flex>
              {props.children}
            </Flex>
          </Flex>
        </Tooltip>
      ) : (
        <>
          <Flex
            //@ts-ignore
            as={motion.div}
            whileHover={{
              textDecoration: 'underline',
            }}
            backgroundColor={props.color}
            //@ts-ignore
            name={'Copy URL'}
            justifyContent={'center'}
            paddingY={'0.5em'}
            borderRadius={'0.5em'}
            borderWidth={'2px'}
            onClick={props.function}
            overflow={'hidden'}
            cursor={'pointer'}
            borderColor={props.borderColor}
          >
            {props.isLoading ? (
              <props.loadingIcon fontSize={'2em'} />
            ) : (
              'Copy URL and Redirect to Etsy'
            )}
          </Flex>
        </>
      )}
    </>
  )
}

export default function ActionButtonDrawer({
  geometryState,
  etsy,
  router,
  websiteState,
}: {
  geometryState: GeometryState
  etsy?: boolean
  router: NextRouter
  websiteState: WebsiteState
}) {
  const buttonColor = useColorModeValue(
    'background.light',
    'rgba(255,255,255,0.2)'
  )
  const shouldShowAccordingToScreenSize = useBreakpointValue({
    base: false,
    md: true,
  })

  const [doesDeviceSupportIosAR, setDoesDeviceSupportIosAR] = useState(false)

  useEffect(() => {
    const a = document.createElement('a')
    setDoesDeviceSupportIosAR(a.relList.supports('ar'))
  }, [])

  return (
    <Flex
      direction={{ base: 'row', md: 'column' }}
      marginRight={{ base: '0em', md: '1em' }}
      marginBottom={{ base: '1em', md: '0em' }}
      position={'relative'}
    >
      <Flex
        fontSize={{ base: '8px', md: '10px' }}
        direction={{ base: 'row', md: 'column' }}
        alignItems={'flex-end'}
        position={'absolute'}
        right={{ base: 'unset', md: '0' }}
        bottom={{ md: 'unset', base: '0' }}
        sx={{ '::-webkit-scrollbar': { display: 'none' } }}
        maxWidth={'70vw'}
      >
        {shouldShowAccordingToScreenSize && (
          <CollapseButton
            sidebarOpen={websiteState.sidebarIsOpen}
            setSidebarIsOpen={websiteState.setSidebarIsOpen}
            color={geometryState.color}
            buttonColor={buttonColor}
          />
        )}

        {geometryState.modelType.id === modelTypes.stacker.id && (
          <ObjectSwitcherButton
            websiteState={websiteState}
            geometryState={geometryState}
            color={geometryState.color}
            buttonColor={buttonColor}
          />
        )}

        {shouldShowAccordingToScreenSize && (
          <RotatorButton
            zRotationMultiplier={websiteState.zRotationMultiplier}
            setZRotationMultiplier={websiteState.setZRotationMultiplier}
            color={geometryState.color}
            buttonColor={buttonColor}
          />
        )}

        {!etsy && (
          <CopyButton
            buttonColor={buttonColor}
            geometryState={geometryState}
            websiteState={websiteState}
            router={router}
          />
        )}

        <ResetButton
          router={router}
          geometryState={geometryState}
          buttonColor={buttonColor}
        />

        <UndoButton buttonColor={buttonColor} geometryState={geometryState} />

        {etsy && (
          <HelpButton
            setShowTutorialPopup={websiteState.setShowTutorialPopup}
            buttonColor={buttonColor}
          />
        )}

        <DarkModeButton buttonColor={buttonColor} />
        <Box height={'4em'} />

        {shouldShowAccordingToScreenSize && (
          <DownloadSTLButton
            hidden={{ base: true, md: false }}
            buttonColor={buttonColor}
            geometryState={geometryState}
          />
        )}

        <IosARExpButton
          buttonColor={buttonColor}
          geometryState={geometryState}
        />

        {!etsy && shouldShowAccordingToScreenSize && (
          <ChangeNumericMode
            color={geometryState.color}
            setNumericalMode={websiteState.setNumericalMode}
            numericalMode={websiteState.numericalMode}
            buttonColor={buttonColor}
          />
        )}

        <InfoButton
          color={geometryState.color}
          setInfoMode={websiteState.setInfoMode}
          infoMode={websiteState.infoMode}
          buttonColor={buttonColor}
        />

        <Box height={'4em'} />

        {/*  <InstagramButton router={router} buttonColor={buttonColor} /> */}
      </Flex>
    </Flex>
  )
}
