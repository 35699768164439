import { ImSpinner2 } from 'react-icons/im'
import { motion } from 'framer-motion'
import { transition } from 'lib/constants'

const SpinnerIcon = () => {
  const variants = {
    initial: { scale: 0.6, rotate: 90 },
    animate: { scale: 1, rotate: 0, transition },
    whileTap: { scale: 0.95, rotate: 15 },
  }

  return (
    <motion.div
      width='2em'
      height='2em'
      animate={{ rotate: 360 }}
      transition={{ ease: 'linear', repeat: Infinity, duration: 1.5 }}
    >
      <ImSpinner2 fontSize={'2em'} />
    </motion.div>
  )
}

export default SpinnerIcon
