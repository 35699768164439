import { BiDownload } from 'react-icons/bi'
import { STLExporter } from 'three/examples/jsm/exporters/STLExporter'
import { MeshMaker } from 'lib/mesh-maker'
import { AnimatedButton } from '../action-button-drawer'

export default function DownloadSTLButton(props) {
  const exporter = new STLExporter()

  const exportSTL = () => {
    let meshMaker = new MeshMaker(props.geometryState, false, false)
    const exportMesh = meshMaker.getMesh(true, false, 0.0015, 0.005)[0]
    exportMesh.geometry.scale(10, 10, 10)
    const result = exporter.parse(exportMesh)
    const link = document.createElement('a')
    link.style.display = 'none'
    document.body.appendChild(link)
    link.href = URL.createObjectURL(new Blob([result], { type: 'text/plain' }))
    link.download =
      'Your' +
      props.geometryState.modelType.displayName.replace(/\s/g, '') +
      '.stl'
    link.click()
  }

  return (
    <AnimatedButton
      buttonColor={props.buttonColor}
      function={exportSTL}
      icon={BiDownload}
      text={'Export STL'}
    />
  )
}
