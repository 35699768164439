import { TbAugmentedReality } from 'react-icons/tb'
import { USDZExporter } from 'three/examples/jsm/exporters/USDZExporter'
import { MeshMaker } from 'lib/mesh-maker'
import { AnimatedButton } from '../action-button-drawer'

export default function IosARExpButton(props: any) {
  const exporter = new USDZExporter()
  // const loader = new GLTFLoader().setPath('three/models/gltf/DamagedHelmet/glTF/');
  const exportUSDZ = async () => {
    let meshMaker = new MeshMaker(props.geometryState, false, false)
    const exportMesh = meshMaker.getMeshAR(props.scene)
    const result = await exporter.parse(exportMesh)
    const link = document.createElement('a')
    link.rel = 'ar'
    document.body.appendChild(link)

    const image = document.createElement('img')
    image.src = 'static/background.png'
    link.appendChild(image)

    link.href = URL.createObjectURL(
      new Blob([result], { type: 'application/octet-stream' })
    )
    link.download = 'experience.usdz'

    link.click()

    document.body.removeChild(link)
  }

  return (
    <AnimatedButton
      buttonColor={props.buttonColor}
      function={exportUSDZ}
      icon={TbAugmentedReality}
      text={'View in AR'}
    />
  )
}
