import { RiAddBoxFill } from 'react-icons/ri'
import { PolygonShape } from 'lib/mesh-maker'
import styles from '../styles/AddButton.module.css'
import { getProfile } from './new-shape-selector'
import { pickTextColorBasedOnBgColor } from 'lib/utils'
import { constants } from 'lib/constants'
import { ProfilesType } from 'lib/state'

export default function AddButton(props: {
  profileHook: [
    ProfilesType,
    (
      value: ProfilesType | ((prevValue: ProfilesType) => ProfilesType),
      addToPrevious?: boolean | undefined
    ) => void
  ]
  editorWidth: number
  color: string
}) {
  const [profiles, setProfiles] = props.profileHook

  const addNewProfile = () => {
    let newProfiles = { ...profiles }
    const prevProfile = getProfile(
      newProfiles.intermediateShapes.length,
      newProfiles
    )
    const nextProfile = getProfile(-1, newProfiles)

    newProfiles.intermediateShapes.push(
      new PolygonShape(
        6,
        (prevProfile.zPosition + nextProfile.zPosition) / 2,
        0
      )
    )

    setProfiles(newProfiles)
  }
  return (
    <div className={styles.container} style={{ width: props.editorWidth }}>
      <RiAddBoxFill
        color={pickTextColorBasedOnBgColor(props.color, 'white', 'black')}
        className={styles.button}
        onClick={addNewProfile}
      ></RiAddBoxFill>
    </div>
  )
}
