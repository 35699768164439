import { GiHamburgerMenu } from 'react-icons/gi'
import { ImCross } from 'react-icons/im'
import { AnimatedButton } from '../action-button-drawer'

export default function CollapseButton(props) {
  const SwitchIcon = props.sidebarOpen ? ImCross : GiHamburgerMenu
  const iconText = props.sidebarOpen ? 'Close Sidebar' : 'Open Sidebar'
  const sidebarToggle = () => props.setSidebarIsOpen((v) => !v)
  return (
    <AnimatedButton
      isActive={props.sidebarOpen}
      color={props.color}
      buttonColor={props.buttonColor}
      function={sidebarToggle}
      icon={SwitchIcon}
      text={iconText}
    />
  )
}
