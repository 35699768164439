import { TbWaveSine } from 'react-icons/tb'
import {
  AspectRatio,
  Box,
  Button,
  Flex,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useColorModeValue,
  useStyleConfig,
} from '@chakra-ui/react'
import { useSize } from '@chakra-ui/react-use-size'
import { MutableRefObject, useEffect, useRef, useState } from 'react'
import Draggable, {
  DraggableCore,
  DraggableData,
  DraggableEvent,
} from 'react-draggable'
import { GoChevronDown } from 'react-icons/go'
import { MdDeleteOutline } from 'react-icons/md'
import { CatmullRomCurve3, Vector2, Vector3 } from 'three'
import { isTouchDevice } from 'lib/commons'
import { constants, modelTypes } from 'lib/constants'
import {
  CircleShape,
  CustomShape,
  IneShape,
  OvalShape,
  PolygonShape,
  ShapeCurve,
  ShapeTypeFlags,
} from 'lib/mesh-maker'
import styles from '../styles/SplineEditor.module.css'
import CustomSlider from 'components/custom-slider'
import { IneKnot, IneSpline } from 'lib/spline-classes'
import { CustomShapeSpline, CustomShapeKnot } from 'lib/spline-classes'
import { ProfilesType, StateProps } from 'lib/state'

export const getProfile = (index: number, profiles: ProfilesType) => {
  if (index == 0) return profiles.startProfile
  else if (index == -1) return profiles.endProfile
  else {
    const allShapes = [
      profiles.startProfile,
      ...profiles.intermediateShapes,
      profiles.endProfile,
    ]

    if (index < allShapes.length) return allShapes[index]
    else throw 'Trying to access a profile that does not exist in profiles'
  }
}

export const setProfile = (
  index: number,
  profiles: ProfilesType,
  newShape: ShapeCurve
) => {
  if (index == 0) profiles.startProfile = newShape
  else if (index == -1) profiles.endProfile = newShape
  else profiles.intermediateShapes[index - 1] = newShape
}

export const deleteProfile = (index: number, profiles: ProfilesType) => {
  return profiles.intermediateShapes.filter(
    (_, profileIndex) => profileIndex != index - 1
  )
}

//TODO remove any
export function SliderContainer(props: any) {
  const [numericInputValue, setNumericInputValue] = useState(props.value)

  useEffect(() => {
    setNumericInputValue(props.value)
  }, [props.numerical])

  if (props.numerical) {
    const changeHandler = (value: any) => {
      setNumericInputValue(value.target.value)
      if (value.target.value.length == 0) {
        props.onChange(props.defaultvalue)
      } else if (value.target.value <= props.min) {
        props.onChange(props.min)
      } else if (value.target.value >= props.max) {
        props.onChange(props.max)
      } else props.onChange(value.target.value)
    }

    return (
      <Flex
        marginTop={'0.5em'}
        width={'100%'}
        position={'relative'}
        __css={props.pickerstyles}
      >
        <Flex fontSize={'0.75em'} alignItems={'center'}>
          {props.text}
          <Input
            type={'number'}
            fontSize={'1em'}
            height={'1.3em'}
            border={0}
            marginLeft={'0.5em'}
            placeholder={numericInputValue}
            min={props.min}
            max={props.max}
            step={props.step}
            value={numericInputValue}
            onChange={changeHandler}
          />
        </Flex>
      </Flex>
    )
  } else
    return (
      <Flex
        marginTop={'0.5em'}
        width={'100%'}
        position={'relative'}
        __css={props.pickerstyles}
      >
        <Flex fontSize={'0.75em'} alignItems={'center'}>
          {props.text}
          <CustomSlider
            geometryState={props.geometryState}
            setModelBeingUpdated={props.setModelBeingUpdated}
            sliderProps={{
              orientation: 'horizontal',
              width: '100%',
              min: props.min,
              step: props.step,
              onChange: props.onChange,
              max: props.max,
              value: props.value,
            }}
            containerProps={{ marginLeft: '1em' }}
          />
        </Flex>
      </Flex>
    )
}

//TODO remove any
function DraggableKnot(props: any) {
  const [selfPosition, setSelfPosition] = useState({
    x: props.position.x,
    y: props.position.y,
  })

  const rotationDegrees = props.rotation
  const rotationRadians = (rotationDegrees * Math.PI) / 180

  const positionUpdater = (event: DraggableEvent, info: DraggableData) => {
    const position = (() => {
      if (
        Math.cos(rotationRadians) < 1 / Math.sqrt(2) &&
        Math.cos(rotationRadians) > -1 / Math.sqrt(2)
      )
        return {
          x: info.x,
          y:
            props.editorSize.height / 2 -
            (info.x - props.editorSize.width / 2) *
              (1 / Math.tan(rotationRadians)),
        }
      else
        return {
          x:
            (props.editorSize.height / 2 - info.y) * Math.tan(rotationRadians) +
            props.editorSize.width / 2,
          y: info.y,
        }
    })()

    if (position.x < 0) {
      position.x = 0
      position.y =
        props.editorSize.height / 2 -
        (position.x - props.editorSize.width / 2) *
          (1 / Math.tan(rotationRadians))
    } else if (position.x > props.editorSize.width) {
      position.x = props.editorSize.width
      position.y =
        props.editorSize.height / 2 -
        (position.x - props.editorSize.width / 2) *
          (1 / Math.tan(rotationRadians))
    }
    if (position.y < 0) {
      position.y = 0
      position.x =
        (props.editorSize.height / 2 - position.y) * Math.tan(rotationRadians) +
        props.editorSize.width / 2
    } else if (position.y > props.editorSize.height) {
      position.y = props.editorSize.height
      position.x =
        (props.editorSize.height / 2 - position.y) * Math.tan(rotationRadians) +
        props.editorSize.width / 2
    }

    return position
  }

  const onStartHandler = () => {
    props.geometryState.putCurrentStateInPreviousStates()
    props.setModelBeingUpdated(true)
  }

  const onStopHandler = () => {
    props.setModelBeingUpdated(false)
  }

  return (
    <DraggableCore
      disabled={!props.isEnabled}
      onDrag={(event, info) => {
        props.updatePositionOnDrag(props.index, info, positionUpdater)
      }}
      onStart={onStartHandler}
      onStop={onStopHandler}
    >
      <div
        style={{
          position: 'absolute',
          left: props.position.x,
          top: props.position.y,
          zIndex: 2,
        }}
      >
        <Box
          width={isTouchDevice() ? 20 + 'px' : 13 + 'px'}
          height={isTouchDevice() ? 20 + 'px' : 13 + 'px'}
          borderRadius={props.isEnabled ? '50%' : '10%'}
          borderWidth={props.isEnabled ? '3px' : '0px'}
          borderColor={props.strokeColor}
          transform={'translateY(-50%) translateX(-50%)'}
          backgroundColor={
            props.isEnabled ? props.backgroundColor : props.strokeColor
          }
          _hover={{ backgroundColor: props.strokeColor }}
          _active={
            isTouchDevice()
              ? {
                  backgroundColor: props.strokeColor,
                  transform: 'scale(2)',
                }
              : { backgroundColor: props.strokeColor }
          }
          zIndex={2}
        ></Box>
      </div>
    </DraggableCore>
  )
}

//TODO remove from here because it's also defined in shape-classes
export const getInitialShapes = (modelType: string) => {
  if (modelType != modelTypes.boucle.id)
    return {
      startProfile: new CircleShape(10, 0, 0),
      endProfile: new CircleShape(10, 1, 0),
      intermediateShapes: [],
    }
  else {
    const knots = [
      new IneKnot(new Vector2(0.13, 0.34), 0, false, 1, true, false),
      new IneKnot(new Vector2(0.55, 0.34), 0, false, 0, true, false),
      new IneKnot(new Vector2(0.68, 0.27), 0, false, 1, true, false),
      new IneKnot(
        new Vector2(0.7118119220344388, 0.20817866941698554),
        0,
        false,
        0,
        true,
        false
      ),
      new IneKnot(
        new Vector2(0.7458255274766157, 0.13675009798841412),
        0,
        false,
        1,
        true,
        false
      ),
      new IneKnot(
        new Vector2(0.7526282485650511, 0.2489949959475978),
        0,
        false,
        1,
        true,
        false
      ),
      new IneKnot(
        new Vector2(0.6846010376806972, 0.36804261499521684),
        0,
        false,
        0,
        true,
        false
      ),
      new IneKnot(
        new Vector2(0.6369819900616497, 0.47688615241018284),
        0,
        false,
        1,
        true,
        false
      ),
      new IneKnot(
        new Vector2(0.7356214458439626, 0.7115800299612033),
        0,
        false,
        0,
        true,
        false
      ),
      new IneKnot(
        new Vector2(0.7356214458439626, 0.8705164072464924),
        0,
        false,
        1,
        true,
        false
      ),
      new IneKnot(
        new Vector2(0.6573901533269558, 0.7557977170360332),
        0,
        false,
        1,
        true,
        false
      ),
      new IneKnot(
        new Vector2(0.5417438948235545, 0.5439857950015944),
        0,
        false,
        0,
        true,
        false
      ),
      new IneKnot(
        new Vector2(0.48392076557185376, 0.47255722357302293),
        0,
        false,
        1,
        true,
        false
      ),
      new IneKnot(
        new Vector2(0.43630171795280615, 0.5337817133689413),
        0,
        false,
        1,
        true,
        false
      ),
      new IneKnot(
        new Vector2(0.43630171795280615, 0.7480674276546556),
        0,
        false,
        0,
        true,
        false
      ),

      new IneKnot(
        new Vector2(0.29344457509566324, 0.8705164072464924),
        0,
        false,
        1,
        true,
        false
      ),
      new IneKnot(
        new Vector2(0.11317246625212585, 0.7310606249335672),
        0,
        false,
        1,
        true,
        false
      ),
      new IneKnot(
        new Vector2(0.11317246625212585, 0.4113327337771046),
        0,
        false,
        0,
        true,
        false
      ),
    ]

    const spline = new IneSpline(knots, modelTypes.boucle)
    return {
      startProfile: new IneShape(spline, 0, 0),
      endProfile: new IneShape(spline, 1, 0),
      intermediateShapes: [],
    }
  }
}

interface NewShapeSelectorProps extends StateProps {
  borderColor: string
  index: number
}

export default function NewShapeSelector({
  geometryState,
  websiteState,
  borderColor,
  index,
}: NewShapeSelectorProps) {
  const [profiles, setProfiles] = [
    geometryState.profiles,
    geometryState.setProfiles,
  ]

  const [selectedOption, setSelectedOption] = useState(
    getProfile(index, geometryState.profiles).type
  )

  const changeSelectedOption = (value: string) => {
    updateAttributes(
      splinePosition,
      shapeSpline,
      value,
      nKnots,
      nSides,
      tilt,
      ratio,
      true
    )
    setSelectedOption(value)
  }

  const [splinePosition, setSplinePosition] = useState(
    1 - getProfile(index, geometryState.profiles).zPosition
  )

  const changeSplinePosition = (value: number) => {
    updateAttributes(
      value,
      shapeSpline,
      selectedOption,
      nKnots,
      nSides,
      tilt,
      ratio
    )
    setSplinePosition(value)
  }

  const [tilt, setTilt] = useState(
    (getProfile(index, geometryState.profiles).angle * 180) / Math.PI
  )

  const changeTilt = (value: number) => {
    updateAttributes(
      splinePosition,
      shapeSpline,
      selectedOption,
      nKnots,
      nSides,
      value,
      ratio
    )
    setTilt(value)
  }

  const [nSides, setNSides] = useState(
    getProfile(index, geometryState.profiles) instanceof PolygonShape
      ? (getProfile(index, geometryState.profiles) as PolygonShape)
          .numberOfSides
      : 4
  )

  const changeNSides = (value: number) => {
    updateAttributes(
      splinePosition,
      shapeSpline,
      selectedOption,
      nKnots,
      value,
      tilt,
      ratio
    )
    setNSides(value)
  }

  const [nKnots, setNKnots] = useState(
    getProfile(index, geometryState.profiles) instanceof CustomShape
      ? (getProfile(index, geometryState.profiles) as CustomShape).numberOfKnots
      : 8
  )

  const changeNKnots = (value: number) => {
    updateAttributes(
      splinePosition,
      shapeSpline,
      selectedOption,
      value,
      nSides,
      tilt,
      ratio
    )
    setNKnots(value)
  }

  const [shapeSpline, setShapeSpline] = useState(
    getProfile(index, geometryState.profiles) instanceof CustomShape
      ? (getProfile(index, geometryState.profiles) as CustomShape).spline
      : CustomShapeSpline.splineFromNumberOfKnots(nKnots)
  )

  const [ratio, setRatio] = useState(
    getProfile(index, geometryState.profiles) instanceof OvalShape
      ? (getProfile(index, geometryState.profiles) as OvalShape).ratio
      : 0.5
  )

  const changeRatio = (value: number) => {
    updateAttributes(
      splinePosition,
      shapeSpline,
      selectedOption,
      nKnots,
      nSides,
      tilt,
      value
    )
    setRatio(value)
  }

  const updateAttributes = (
    splinePosition: number,
    shapeSpline: CustomShapeSpline,
    selectedOption: string,
    nKnots: number,
    nSides: number,
    tilt: number,
    ratio: number,
    addToUndoList: boolean = false
  ) => {
    let newProfiles = { ...profiles }
    const angle = (tilt * Math.PI) / 180
    let newShape = undefined

    if (selectedOption == constants.shapeNames.circle)
      newShape = new CircleShape(10, 1 - splinePosition, angle)
    else if (selectedOption == constants.shapeNames.polygon)
      newShape = new PolygonShape(nSides, 1 - splinePosition, angle)
    else if (selectedOption == constants.shapeNames.custom)
      if (Number(nKnots) == shapeSpline.knots.length)
        newShape = new CustomShape(shapeSpline, 1 - splinePosition, angle)
      else {
        newShape = new CustomShape(
          CustomShapeSpline.splineFromNumberOfKnots(nKnots),
          1 - splinePosition,
          angle
        )
        setShapeSpline(CustomShapeSpline.splineFromNumberOfKnots(nKnots))
      }
    else if (selectedOption == constants.shapeNames.oval)
      newShape = new OvalShape(ratio, 10, 1 - splinePosition, angle)
    else throw 'The type of shape selected does not exist '

    setProfile(index, newProfiles, newShape)
    setProfiles(newProfiles, addToUndoList)
  }

  const removeProfile = () => {
    let newProfiles = { ...profiles }
    newProfiles.intermediateShapes = deleteProfile(index, newProfiles)
    setProfiles(newProfiles)
  }

  const updatePositionOnDrag = (
    index: number,
    info: DraggableData,
    positionUpdater: (
      _: DraggableEvent | undefined,
      info: DraggableData
    ) => Vector2
  ) => {
    let currentKnots = [...shapeSpline.knots]
    let knotToUpdate = currentKnots[index]

    const restrictedPosition = positionUpdater(undefined, info)
    knotToUpdate.position = CustomShapeKnot.convertEditorPosToNormalizedPos(
      editorSize!,
      restrictedPosition
    )

    const newSpline = new CustomShapeSpline(currentKnots)
    updateAttributes(
      splinePosition,
      newSpline,
      selectedOption,
      nKnots,
      nSides,
      tilt,
      ratio
    )
    setShapeSpline(newSpline)
  }

  const menuButtonRef: MutableRefObject<HTMLButtonElement | null> = useRef(null)
  const menuButtonDimensions = useSize(menuButtonRef)
  const pickerStyles = useStyleConfig('FieldBox', { variant: 'pickerInput' })

  const containerStyles = useStyleConfig('FieldBox')
  const editorRef: MutableRefObject<HTMLDivElement | null> = useRef(null)
  const editorSize = useSize(editorRef)

  const strokeColor = useColorModeValue('black', 'white')
  const backgroundColor = useColorModeValue(
    'background.light',
    'background.dark'
  )

  const isLastOrFirst = index == -1 || index == 0
  // useEffect(updateAttributes, [
  //   editorSize,
  //   splinePosition,
  //   shapeSpline,
  //   selectedOption,
  //   nKnots,
  //   nSides,
  //   tilt,
  //   ratio,
  // ])

  return (
    <Flex
      direction={'column'}
      width={{ base: '210px', sm: '250px', md: '260px' }}
      padding={'0.5em'}
      borderRadius={'0.5em'}
      borderColor={borderColor}
      borderWidth={'2px'}
    >
      <Menu autoSelect={false}>
        {({ isOpen }) => (
          <>
            <MenuButton
              as={Button}
              __css={pickerStyles}
              marginBottom={'0.5em'}
              backgroundColor={
                isOpen
                  ? '#' + geometryState.color
                  : (pickerStyles.backgroundColor as string)
              }
              ref={menuButtonRef}
              width={'100%'}
              _hover={{ backgroundColor: '#' + geometryState.color }}
            >
              <Flex
                width={'100%'}
                height={'100%'}
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                SELECT SHAPE
                <Flex alignItems={'center'}>
                  |
                  <GoChevronDown />
                </Flex>
              </Flex>
            </MenuButton>
            <MenuList
              overflowY={'auto'}
              maxH={'40vh'}
              paddingBottom={'1em'}
              backgroundColor={'#' + geometryState.color}
              width={
                menuButtonDimensions ? menuButtonDimensions.width : 'inherit'
              }
              zIndex={6}
            >
              <MenuItem
                _hover={{
                  fontSize: '1.5em',
                  backgroundColor: 'rgba(0,0,0,0)',
                }}
                transition='all .1s ease'
                onClick={() =>
                  changeSelectedOption(constants.shapeNames.circle)
                }
              >
                {' '}
                <Flex>Circle</Flex>
              </MenuItem>

              <MenuItem
                _hover={{
                  fontSize: '1.5em',
                  backgroundColor: 'rgba(0,0,0,0)',
                }}
                transition='all .1s ease'
                onClick={() =>
                  changeSelectedOption(constants.shapeNames.polygon)
                }
              >
                Polygon
              </MenuItem>

              <MenuItem
                _hover={{
                  fontSize: '1.5em',
                  backgroundColor: 'rgba(0,0,0,0)',
                }}
                transition='all .1s ease'
                onClick={() => changeSelectedOption(constants.shapeNames.oval)}
              >
                Oval
              </MenuItem>

              <MenuItem
                _hover={{
                  fontSize: '1.5em',
                  backgroundColor: 'rgba(0,0,0,0)',
                }}
                transition='all .1s ease'
                onClick={() =>
                  changeSelectedOption(constants.shapeNames.custom)
                }
              >
                Custom
              </MenuItem>

              {!isLastOrFirst && (
                <MenuItem
                  _hover={{
                    fontSize: '1.5em',
                    backgroundColor: 'rgba(0,0,0,0)',
                  }}
                  transition='all .1s ease'
                  onClick={removeProfile}
                >
                  <MdDeleteOutline />
                  REMOVE
                </MenuItem>
              )}
            </MenuList>
          </>
        )}
      </Menu>

      <Flex width={'100%'} position={'relative'} __css={containerStyles}>
        <Flex
          position={'relative'}
          width={'100%'}
          height={'100%'}
          padding={'0.5em'}
        >
          <AspectRatio ratio={1} width={'100%'}>
            <Flex
              ref={editorRef}
              width={'100%'}
              height={'100%'}
              flex={1}
              grow={1}
              shrink={1}
              position={'relative'}
            >
              {editorSize && (
                <svg
                  height={'100%'}
                  width={'100%'}
                  className={styles.svgContainer}
                  style={{ transform: 'rotate(180deg)' }}
                >
                  <polyline
                    className={styles.spline}
                    style={{ stroke: strokeColor }}
                    points={getProfile(index, profiles).getPolylineString(
                      editorSize.width
                    )}
                  />
                </svg>
              )}

              {editorSize &&
                getProfile(index, geometryState.profiles).flags.includes(
                  ShapeTypeFlags.showSplineEditorInAdvancedEditor
                ) &&
                shapeSpline.knots.map((knot) => {
                  const rotation = (knot.index * 360) / shapeSpline.totalKnots
                  return (
                    <DraggableKnot
                      geometryState={geometryState}
                      key={knot.index}
                      index={knot.index}
                      position={CustomShapeKnot.convertNormalizedPosToEditorPos(
                        editorSize,
                        knot.position
                      )}
                      updatePositionOnDrag={updatePositionOnDrag}
                      rotation={rotation}
                      strokeColor={strokeColor}
                      backgroundColor={backgroundColor}
                      editorSize={editorSize}
                      isEnabled={true}
                      modelBeingUpdated={websiteState.modelBeingUpdated}
                      setModelBeingUpdated={websiteState.setModelBeingUpdated}
                    />
                  )
                })}
            </Flex>
          </AspectRatio>

          {!websiteState.numericalMode && (
            <CustomSlider
              geometryState={geometryState}
              setModelBeingUpdated={websiteState.setModelBeingUpdated}
              sliderProps={{
                isDisabled: isLastOrFirst,
                orientation: 'vertical',
                value: splinePosition,
                onChange: changeSplinePosition,
                min: 0,
                max: 1,
                step: 0.05,
              }}
              showEnds={!isLastOrFirst}
            />
          )}
        </Flex>
      </Flex>

      {getProfile(index, geometryState.profiles).flags.includes(
        ShapeTypeFlags.showNSidesInAdvancedEditor
      ) && (
        <SliderContainer
          geometryState={geometryState}
          modelBeingUpdated={websiteState.modelBeingUpdated}
          setModelBeingUpdated={websiteState.setModelBeingUpdated}
          numerical={websiteState.numericalMode}
          defaultvalue={4}
          pickerstyles={pickerStyles}
          text={'CORNERS'}
          min={3}
          max={8}
          value={nSides}
          onChange={changeNSides}
          step={1}
        />
      )}

      {getProfile(index, geometryState.profiles).flags.includes(
        ShapeTypeFlags.showTiltInAdvancedEditor
      ) &&
        isLastOrFirst && (
          <SliderContainer
            geometryState={geometryState}
            modelBeingUpdated={websiteState.modelBeingUpdated}
            setModelBeingUpdated={websiteState.setModelBeingUpdated}
            numerical={websiteState.numericalMode}
            defaultvalue={0}
            pickerstyles={pickerStyles}
            text={'ANGLE'}
            min={-270}
            max={270}
            value={tilt}
            onChange={changeTilt}
            step={1}
          />
        )}

      {getProfile(index, geometryState.profiles).flags.includes(
        ShapeTypeFlags.showSplineEditorInAdvancedEditor
      ) && (
        <SliderContainer
          geometryState={geometryState}
          modelBeingUpdated={websiteState.modelBeingUpdated}
          setModelBeingUpdated={websiteState.setModelBeingUpdated}
          numerical={websiteState.numericalMode}
          defaultvalue={8}
          pickerstyles={pickerStyles}
          text={'POINTS'}
          min={6}
          max={14}
          value={nKnots}
          onChange={changeNKnots}
          step={2}
        />
      )}

      {getProfile(index, geometryState.profiles).flags.includes(
        ShapeTypeFlags.showRatioInAdvancedEditor
      ) && (
        <SliderContainer
          geometryState={geometryState}
          modelBeingUpdated={websiteState.modelBeingUpdated}
          setModelBeingUpdated={websiteState.setModelBeingUpdated}
          numerical={websiteState.numericalMode}
          defaultvalue={0.5}
          pickerstyles={pickerStyles}
          text={'RATIO'}
          min={0.2}
          max={0.8}
          value={ratio}
          onChange={changeRatio}
          step={0.05}
        />
      )}

      {websiteState.numericalMode && !isLastOrFirst && (
        <SliderContainer
          geometryState={geometryState}
          modelBeingUpdated={websiteState.modelBeingUpdated}
          setModelBeingUpdated={websiteState.setModelBeingUpdated}
          numerical={websiteState.numericalMode}
          defaultvalue={splinePosition}
          pickerstyles={pickerStyles}
          text={'POSITION'}
          min={0}
          max={1}
          value={splinePosition}
          onChange={changeSplinePosition}
          step={0.05}
        />
      )}
    </Flex>
  )
}
