import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Center,
  Flex,
  useColorModeValue,
} from '@chakra-ui/react'
import Head from 'next/head'
import Script from 'next/script'
import ActionButtonDrawer from 'components/action-button-drawer'
import AddButton from 'components/add-button'
import PortraitCollapseButton from 'components/buttons/portrait-collapse-button'
import ColorPicker from 'components/color-picker'
import DDWOption from 'components/ddw-database-options'
import LampViewer from 'components/lamp-viewer'
import NewShapeSelector, {
  getInitialShapes,
} from 'components/new-shape-selector'
import CopyButton, { CopyButtonEtsy } from 'components/buttons/copy-url-button'
import SplineEditor, { getInitialSpline } from 'components/spline-editor'
import {
  constants,
  getSocketSize,
  isSplineBased,
  modelTypeFlags,
} from 'lib/constants'
import { pickTextColorBasedOnBgColor } from 'lib/utils'
import styles from '../styles/Menu.module.scss'
/* import MenuButton from "components/slide-menu"; */
import CookieConsent from 'react-cookie-consent'
/* import { setConstantValue } from "typescript"; */
import LoadingNotifier from 'components/loading-notifier'
import { generateIndexPageHooks } from 'pages/index-functions'
import TutorialPopup from 'components/tutorial-popup'

export default function Home() {
  const {
    geometryState,
    websiteState,
    router,
    lampSetter,
    meshSetter,
    canvasRef,
    canvasSize,
  } = generateIndexPageHooks()

  const textColor = useColorModeValue('black', 'white')

  return (
    <>
      <Flex
        p={'15px'}
        height={websiteState.hasMounted ? window.innerHeight : '100vh'}
        fontSize={{ base: '0.5em', md: '1em' }}
        direction={{ base: 'column', md: 'row' }}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <div
          className={styles.menuButtonContainer}
          style={{ backgroundColor: 'transparent', marginTop: '1em' }}
        >
          <div
            className={styles.menuButton}
            style={{
              backgroundColor: 'transparent',
              color: textColor,
            }}
          >
            <span
              className={styles.menuButtonText}
              style={{
                fontSize: '1.5em',
              }}
            >
              LYLA
            </span>
          </div>
        </div>
        <Head>
          <title>Lyla Designer</title>
          <meta
            name='description'
            content='Make your own 3D printable designs'
          />
          <link rel='icon' href='/favicon.ico' />
        </Head>
        <Script src='/script.js'></Script>
        {websiteState.showLoader && canvasRef.current && (
          <LoadingNotifier canvasRef={canvasRef} />
        )}
        {websiteState.showTutorialPopup && (
          <TutorialPopup
            setShowTutorialPopup={websiteState.setShowTutorialPopup}
            showTutorialPopup={websiteState.showTutorialPopup}
          />
        )}
        <LampViewer
          canvasRef={canvasRef}
          socketColor={'0xFFFFFF'}
          geometryState={geometryState}
          websiteState={websiteState}
        />
        <Flex
          alignSelf={'center'}
          alignItems={'flex-start'}
          height={{ base: 'unset', md: '100vh' }}
          paddingTop={{ base: '0', md: '15vh' }}
          direction={{ base: 'column', md: 'row' }}
          paddingRight={{
            base: '0em',
            md: websiteState.sidebarIsOpen ? '8em' : '0em',
          }}
          transition='all 1s ease'
        >
          <ActionButtonDrawer
            etsy={true}
            router={router}
            geometryState={geometryState}
            websiteState={websiteState}
          />

          <Accordion
            color={pickTextColorBasedOnBgColor(geometryState.color)}
            justifyContent={'center'}
            width={{
              base: '70vw',
              md: websiteState.sidebarIsOpen ? '20vw' : '0px',
            }}
            minWidth={{
              base: '200px',
              md: websiteState.sidebarIsOpen ? '400px' : '0px',
            }}
            minHeight={'unset'}
            maxHeight={{
              base: websiteState.sidebarIsOpen ? '100vh' : '4em',
              md: 'unset',
            }}
            overflow={'hidden'}
            defaultIndex={0}
            bgColor={'#' + geometryState.color}
            rounded={'lg'}
            boxShadow={'lg'}
          >
            {!websiteState.isLandscape && (
              <Center marginTop={'1em'}>
                <PortraitCollapseButton
                  sidebarOpen={websiteState.sidebarIsOpen}
                  sidebarToggle={websiteState.toggleSidebarOpen}
                />
              </Center>
            )}

            <Center>
              <ColorPicker
                color={geometryState.color}
                setColor={geometryState.setColor}
              />
            </Center>

            <AccordionItem
              borderColor={pickTextColorBasedOnBgColor(
                geometryState.color,
                constants.colors.semiTransparentWhite,
                constants.colors.semiTransparentBlack
              )}
            >
              <AccordionButton>
                {/* 	<Box flex="1" textAlign="left">
									EDITOR
								</Box>
								 	<AccordionIcon /> */}
              </AccordionButton>
              <AccordionPanel
                display={'flex'}
                paddingX={0}
                width='100%'
                justifyContent={'center'}
              >
                {geometryState.modelType.flags.includes(
                  modelTypeFlags.isSplineBased
                ) &&
                  websiteState.hasMounted && (
                    <SplineEditor
                      geometryState={geometryState}
                      websiteState={websiteState}
                    />
                  )}
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem
              borderColor={pickTextColorBasedOnBgColor(
                geometryState.color,
                constants.colors.semiTransparentWhite,
                constants.colors.semiTransparentBlack
              )}
            >
              <Center>
                <DDWOption
                  router={router}
                  geometryState={geometryState}
                  borderColor={pickTextColorBasedOnBgColor(
                    geometryState.color,
                    constants.colors.semiTransparentWhite,
                    constants.colors.semiTransparentBlack
                  )}
                />
              </Center>
              <Center>
                <Flex marginY={'0.5em'} direction={'column'} width={'80%'}>
                  <CopyButtonEtsy
                    geometryState={geometryState}
                    router={router}
                    borderColor={pickTextColorBasedOnBgColor(
                      geometryState.color,
                      constants.colors.semiTransparentWhite,
                      constants.colors.semiTransparentBlack
                    )}
                  />
                </Flex>
              </Center>
            </AccordionItem>
          </Accordion>
        </Flex>
      </Flex>
    </>
  )
}
