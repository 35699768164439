import { DraggableCore, DraggableData, DraggableEvent } from 'react-draggable'
import styles from '../styles/SplineEditor.module.css'

import { useSize } from '@chakra-ui/react-use-size'
import { MutableRefObject, useEffect, useRef, useState } from 'react'

import {
  AspectRatio,
  Box,
  Flex,
  Tooltip,
  useColorModeValue,
  useStyleConfig,
} from '@chakra-ui/react'
import { Vector2, Vector3 } from 'three'
import { constants, editorTypes, ModelType, modelTypes } from 'lib/constants'
import { isTouchDevice } from 'lib/commons'
import { IoArrowUndoCircle, IoRemoveCircle } from 'react-icons/io5'
import { FaRotate } from 'react-icons/fa6'
import { Spline, Knot } from 'lib/spline-classes'
import { GeometryState, WebsiteState } from 'lib/state'

//TODO remove this function from here, it has also been defined in spline-classes
export const getInitalKnotState = (editorTypeID: string) => {
  let newKnots = []

  switch (editorTypeID) {
    case editorTypes.lamp.id:
      newKnots = [
        new Knot(new Vector2(0, 0), 0, false, false, true),
        new Knot(new Vector2(0.25, 0.35), 0, false, false, true),
        new Knot(new Vector2(0.4, 0.6), 0, false, false, true),
      ]
      break

    case editorTypes.bigLamp.id:
      newKnots = [
        new Knot(new Vector2(0, 0), 0, false, false, true),
        new Knot(new Vector2(0.25, 0.35), 0, false, false, true),
        new Knot(new Vector2(0.4, 0.6), 0, false, false, true),
      ]
      break
    case editorTypes.pablo.id:
      newKnots = [
        new Knot(new Vector2(0.72, 0), 1, false, false, false),
        new Knot(new Vector2(0.72, 0.166), 0, false, false, true),
        new Knot(new Vector2(0.665, 0.206), 0, false, false, true),
        new Knot(new Vector2(0.383, 0.315), 0, false, false, true),
        new Knot(new Vector2(0.72, 0.743), 0, false, false, true),

        new Knot(new Vector2(0.72, 0.84), 0, false, false, false),
      ]
      break

    case editorTypes.boucle.id:
      newKnots = [
        new Knot(new Vector2(0.02, 0), 1, false, false, true),
        new Knot(new Vector2(0.02, 0.84), 1, false, false, true),
      ]
      break

    case editorTypes.sketch.id:
      newKnots = [
        new Knot(new Vector2(0.02, 0), 1, false, false, true),
        new Knot(new Vector2(0.02, 0.84), 1, false, false, true),
      ]
      break

    case editorTypes.bubble.id:
      newKnots = [
        new Knot(new Vector2(0.35, 0), 1, false, false, true),
        new Knot(new Vector2(0.35, 0.84), 1, false, false, true),
      ]
      break

    default:
      newKnots = [
        new Knot(new Vector2(0.35, 0), 1, false, false, true),
        new Knot(new Vector2(0.35, 0.84), 1, false, false, true),
      ]
      break
  }

  return newKnots
}

export const getInitialSpline = (modelType: ModelType) => {
  let newKnots = getInitalKnotState(modelType.editorType.id)
  return new Spline(newKnots, modelType)
}

//TODO remove any from next function

export function DraggableKnot(props: any) {
  const positionUpdater = (
    _: DraggableEvent | undefined,
    info: DraggableData
  ): Vector2 => {
    const position = new Vector2(info.x, info.y)
    if (info.x < 0) {
      position.x = 0
    } else if (info.x > props.editorSize.width)
      position.x = props.editorSize.width
    if (info.y < 0) {
      position.y = 0
    } else if (info.y > props.editorSize.height)
      position.y = props.editorSize.height

    if (props.flipped) {
      position.y = props.editorSize.height - position.y
    }
    return position
  }

  const onStartHandler = () => {
    props.geometryState.putCurrentStateInPreviousStates()
    props.setActiveIndex(props.index)
    props.setModelBeingUpdated(true)
    props.setHideModifier(true)
  }

  const onStopHandler = () => {
    props.setModelBeingUpdated(false)
    props.setHideModifier(false)
  }

  const dotRadius = isTouchDevice() ? 20 + 'px' : 13 + 'px'
  return (
    <DraggableCore
      disabled={!props.isEnabled}
      onDrag={(_, info) => {
        props.updatePositionOnDrag(props.index, info, positionUpdater)
      }}
      onStart={onStartHandler}
      onStop={onStopHandler}
    >
      <div
        style={{
          position: 'absolute',
          left: props.position.x,
          top: props.position.y,
          zIndex: 2,
        }}
      >
        <Box
          width={dotRadius}
          height={dotRadius}
          borderRadius={props.isEnabled ? '50%' : '10%'}
          borderWidth={props.isEnabled ? '3px' : '0px'}
          borderColor={props.strokeColor}
          transform={'translateY(-50%) translateX(-50%)'}
          backgroundColor={
            props.isEnabled && props.activeIndex != props.index
              ? props.backgroundColor
              : props.strokeColor
          }
          _hover={{ backgroundColor: props.strokeColor }}
          zIndex={2}
          onContextMenu={(event) => {
            event.stopPropagation()
            props.deleteKnot(event, props.index)
          }}
        >
          <Tooltip label='Right click to delete' zIndex={3} openDelay={1000}>
            <Box width={'100%'} height={'100%'} />
          </Tooltip>
        </Box>
      </div>
    </DraggableCore>
  )
}

interface SplineEditorProps {
  geometryState: GeometryState
  websiteState: WebsiteState
}

export default function SplineEditor({
  geometryState,
  websiteState,
}: SplineEditorProps) {
  const [spline, setSpline] = [geometryState.spline, geometryState.setSpline]
  const editorType = geometryState.modelType.editorType

  const printBedWidthToHeightRatio =
    editorType.getSmallerXYDimension() / editorType.printerType.dimensions.z

  const modelType = geometryState.modelType
  const socketSize = modelType.socketSize

  const editorRef: MutableRefObject<HTMLDivElement | null> = useRef(null)
  const editorSize = useSize(editorRef)
  const [activeIndex, setActiveIndex] = useState(-1)

  const flipped =
    modelType.id == modelTypes.leroyMerlin.id ||
    modelType.id == modelTypes.vase.id ||
    modelType.id == modelTypes.smallVase.id ||
    (websiteState.selectedObjectIndex === 1 &&
      websiteState.exportedObjects[1].flipped)

  const [previousStates, setPreviousStates] = useState([])

  useEffect(() => {
    if (spline.knots.length == 0) {
      setSpline(getInitialSpline(modelType))
    }
  }, [spline])

  //TODO remove any type
  const addKnot = (event: any) => {
    const bounds = event.currentTarget.getBoundingClientRect()
    const y = event.clientY - bounds.top
    const x = event.clientX - bounds.left

    let currentKnots = [...spline.knots]
    const currentOverhang = spline.overhangAngleDegrees

    let position = new Vector2(0, 0)

    if (currentKnots.length > 0) {
      position = new Vector2(x, flipped ? editorSize!.height - y : y)
      currentKnots[spline.knots.length - 1].isLast = false
    }

    const newKnot = new Knot(
      Knot.convertEditorPosToNormalizedPos(editorSize!, position),
      0,
      true,
      false,
      true
    )

    const index = currentKnots.findIndex(
      (knot) => knot.position.y > newKnot.position.y
    ) //finds index of the point before which the new knot should be placed

    index == -1
      ? currentKnots.push(newKnot)
      : currentKnots.splice(index, 0, newKnot)
    if (
      ((editorType.id == editorTypes.lamp.id ||
        editorType.id == editorTypes.bigLamp.id ||
        editorType.id === editorTypes.smallVase.id ||
        editorType.id === editorTypes.stacker.id) &&
        (index == -1 || spline.knots[index].locked != 3)) ||
      (editorType.id == editorTypes.pablo.id && index != 0 && index != -1)
    ) {
      setSpline(new Spline(currentKnots, modelType, currentOverhang))
      setActiveIndex(-1)
    }
  }

  useEffect(() => {
    if (spline.knots.length != 0)
      setSpline(
        new Spline([...spline.knots], modelType, spline.overhangAngleDegrees)
      )
  }, [socketSize, modelType])

  const updatePositionOnDrag = (
    index: number,
    info: DraggableData,
    positionUpdater: (
      _: DraggableEvent | undefined,
      info: DraggableData
    ) => Vector2
  ) => {
    let currentKnots = [...spline.knots].map((knot) => knot.clone())
    let knotToUpdate = currentKnots[index]

    const restrictedPosition = positionUpdater(undefined, info)
    knotToUpdate.updatePosition(
      Knot.convertEditorPosToNormalizedPos(editorSize!, restrictedPosition)
    )

    let newSpline = new Spline(
      currentKnots,
      modelType,
      spline.overhangAngleDegrees
    )
    setSpline(newSpline, false)
  }

  const deleteKnot = (event: DraggableEvent, delIndex: number) => {
    event.preventDefault()

    if (spline.knots.length > 2 && spline.knots[delIndex].deletable) {
      let currentKnots = [...spline.knots].filter(
        (_, index) => index != delIndex
      )
      setSpline(
        new Spline(currentKnots, modelType, spline.overhangAngleDegrees)
      )
      return false
    }
  }

  const containerStyle = useStyleConfig('FieldBox')
  const containerWidth = 350
  const strokeColor = useColorModeValue('black', 'white')
  const backgroundColor = useColorModeValue(
    'background.light',
    'background.dark'
  )

  const undoFunction = () => {
    geometryState.undoState()
  }

  return (
    <Tooltip label={'Click to add control point'} placement={'top'}>
      {
        //TODO make sure getOverhangPolylineStrings() is only executed once during a render becuase it is a computationally intensive function.
      }
      <AspectRatio
        ratio={printBedWidthToHeightRatio}
        width={'80%'}
        maxWidth={containerWidth + 'px'}
      >
        <Flex
          position={'relative'}
          __css={containerStyle}
          padding={'13px'}
          transform={flipped ? 'scaleY(-1)' : 'unset'}
        >
          <Flex
            ref={editorRef}
            position={'relative'}
            width={'100%'}
            height={'100%'}
            onMouseDown={(event) => {
              event.target === event.currentTarget ? addKnot(event) : false
            }}
          >
            {editorSize &&
              spline.knots.map((knot, index) => {
                const isEnabled = knot.locked != 3
                return (
                  <DraggableKnot
                    geometryState={geometryState}
                    key={index}
                    index={index}
                    spline={spline}
                    position={Knot.convertNormalizedPosToEditorPos(
                      editorSize,
                      knot.position
                    )}
                    updatePositionOnDrag={updatePositionOnDrag}
                    editorSize={editorSize}
                    isEnabled={isEnabled}
                    strokeColor={strokeColor}
                    backgroundColor={backgroundColor}
                    flipped={flipped}
                    deleteKnot={deleteKnot}
                    isTouchDevice={isTouchDevice}
                    activeIndex={activeIndex}
                    setActiveIndex={setActiveIndex}
                    setModelBeingUpdated={websiteState.setModelBeingUpdated}
                    setHideModifier={websiteState.setHideModifier}
                  />
                )
              })}

            {editorSize && spline.curve && (
              <svg height='100%' width='100%' className={styles.svgContainer}>
                <polyline
                  className={styles.spline}
                  style={{ stroke: strokeColor, strokeOpacity: 0.05 }}
                  points={spline ? spline.getPolylineString(editorSize) : ''}
                />
                <polyline
                  className={styles.spline}
                  style={{ stroke: strokeColor }}
                  points={
                    spline ? spline.getSafePolylineString(editorSize) : ''
                  }
                />
                {/* only to be used for debugging <polyline className={styles.spline} style={{ stroke: "red" }} points={spline ? spline.getSafePolylineString(editorSize) : ""} /> */}
                {/* spline
                  .getOverhangPolylineStrings(editorSize)
                  .map((pointsString, index) => {
                    return (
                      <polyline
                        key={index}
                        className={styles.spline + ' ' + styles.overhang}
                        points={pointsString}
                      />
                    )
                  }) */}
              </svg>
            )}
          </Flex>
          <Box
            position={'absolute'}
            bottom={flipped ? 'unset' : '1em'}
            right={'1em'}
            top={flipped ? '1em' : 'unset'}
            transform={flipped ? 'scaleY(-1)' : 'unset'}
            zIndex={1}
            color={strokeColor}
            onClick={undoFunction}
          >
            <IoArrowUndoCircle
              fontSize={websiteState.isLandscape ? '2em' : '3.5em'}
            />
          </Box>

          <Box
            position={'absolute'}
            bottom={flipped ? 'unset' : '1em'}
            left={'1em'}
            top={flipped ? '1em' : 'unset'}
            transform={flipped ? 'scaleY(-1)' : 'unset'}
            zIndex={1}
            color={strokeColor}
            onClick={(event) =>
              deleteKnot(
                event,
                activeIndex != -1 ? activeIndex : spline.knots.length - 1
              )
            }
          >
            <IoRemoveCircle
              fontSize={websiteState.isLandscape ? '2em' : '3.5em'}
            />
          </Box>

          {websiteState.selectedObjectIndex === 1 && (
            <Box
              position={'absolute'}
              bottom={flipped ? '1em' : 'unset'}
              right={'1em'}
              top={flipped ? 'unset' : '1em'}
              transform={flipped ? 'scaleY(-1)' : 'unset'}
              zIndex={1}
              color={strokeColor}
              onClick={() => {
                geometryState.setFlipped((v) => !v)
              }}
            >
              <FaRotate fontSize={websiteState.isLandscape ? '2em' : '3.5em'} />
            </Box>
          )}
        </Flex>
      </AspectRatio>
    </Tooltip>
  )
}
