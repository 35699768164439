import {
  AspectRatio,
  Flex,
  keyframes,
  ScaleFade,
  Spinner,
  useDisclosure,
} from '@chakra-ui/react'
import { motion } from 'framer-motion'
import { listOfPickerColors } from 'lib/constants'

export default function TutorialPopup(props) {
  const animationKeyframes = keyframes`
  0% { transform: scale(0.5) rotate(0); border-radius: 20%; }
  100% { transform: scale(1) rotate(0); border-radius: 20%; }`

  const animation = `${animationKeyframes} 1s ease-in-out`

  const onClickFunction = () => {
    props.setShowTutorialPopup(false)
    window.localStorage.setItem('showTutorial', false)
  }
  return (
    <Flex
      position={'absolute'}
      as={motion.div}
      animation={animation}
      width={'100%'}
      height={'100%'}
      zIndex={16}
      left={0}
      top={0}
      backdropFilter='auto'
      backdropBlur='8px'
      alignItems={'center'}
      justifyContent={'center'}
      direction={'column'}
    >
      <Flex
        boxShadow='lg'
        width={{ base: '90%', md: '50%' }}
        padding={'4em'}
        borderRadius={'2em'}
        alignItems={'center'}
        justifyContent={'center'}
        backgroundColor={'#' + listOfPickerColors[0]}
        direction={'column'}
      >
        <Flex paddingBottom={'0.5em'} fontSize={'1.5em'}>
          Please watch the tutorial before you let your creativity fly
        </Flex>
        <AspectRatio width={'100%'} ratio={16 / 9}>
          <iframe
            title='naruto'
            src='https://www.youtube.com/embed/8w5_5-Viu9Y'
            allowFullScreen
          />
        </AspectRatio>
        <Flex
          justifyContent={'center'}
          backgroundColor={'#' + listOfPickerColors[0]}
          paddingY={'0.5em'}
          borderRadius={'0.5em'}
          borderWidth={'2px'}
          borderColor={'black'}
          width={'25%'}
          cursor={'pointer'}
          marginTop={'1em'}
          onClick={onClickFunction}
        >
          I am ready
        </Flex>
      </Flex>
    </Flex>
  )
}
